.profile-modal {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.profile-modal.open {
  display: block;
}

.profile-modal .modal-content {
  color: var(--background-color);
  background-color: var(--green-primary-color);
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 30%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.main_info {
  display: flex;
  gap: 20px;
}

.main_info .user_avatar {
}

.main_info .user_avatar img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.detail_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detail_info .display_info {
  display: flex;
  gap: 5px;
}

.user_display_name {
  color: var(--text-title-color);
  font-size: 20px;
  font-weight: 600;
}

.user_display_name input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 8px 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border 0.3s;
  transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
}

.user_level {
  color: #ededef;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d1f1e;
  border-radius: 5px;
}

.detail_display_info {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detail_info .join_date {
  color: var(--text-description-color);
  font-size: 12px;
  font-weight: 600;
}

.exp {
  color: var(--text-description-color);
  font-size: 12px;
  font-weight: 600;
}

.detail_info .email {
  display: flex;
  align-items: center;
  color: var(--text-description-color);
  font-size: 12px;
  font-weight: 600;
}

.detail_info .email ion-icon {
  padding-left: 5px;
  color: var(--border-color);
  font-size: 15px;
  font-weight: 600;
}

.detail_info .email .verify ion-icon {
  color: var(--text-description-color);
  font-size: 15px;
  font-weight: 600;
}

.email .email_left {
  width: 100%;
  display: flex;
  align-items: center;
}

.email .email_left span {
  padding-right: 5px;
}

.email .email_right {
  width: 100%;
  display: flex;
  align-items: center;
}

.btn_verify {
  margin-left: 50px;
  cursor: pointer;
  background-color: #21b15d59;
  padding: 5px;
  border-radius: 5px;
}

.btn_verify:hover {
  background-color: var(--background-color);
  color: var(--gray-primary-color);
  padding: 5px;
  border-radius: 5px;
}

.sub_info {
  margin: 10px;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}

.sub_info .user_uid {
  display: flex;
  align-items: center;
}

.user_uid_title {
  width: 100%;
  display: flex;
  align-items: center;
}

.user_uid_title ion-icon {
  padding-right: 8px;
  margin-top: 3px;
  font-size: 18px;
}

.user_uid_info {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user_statis {
  display: grid;
  justify-content: center;
  align-items: center;
  /* Tạo 2 hàng bằng cách lặp lại 1fr (fractional unit) */
  grid-template-columns: repeat(3, 1fr);
  /* Tạo 3 cột bằng cách lặp lại 1fr */
  gap: 10px;
}

.statis_card {
  background-color: #21b15d59;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statis_numb {
  font-weight: 600;
}

.statis_text {
  font-size: 14px;
}

.user_chart {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart_title {
  font-weight: 600;
}

.chart_detail {
  width: 100%;
}

.user_history {
}

.user_history .tabs {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.tabs .tab_items {
  cursor: pointer;
  font-weight: 600;
  background-color: #21b15d59;
  padding: 10px;
  border-radius: 10px;
}

.tabs .tab_items:hover {
  font-weight: 600;
  background-color: var(--background-color);
  color: var(--gray-primary-color);
  padding: 10px;
  border-radius: 10px;
}

.tabs .tab_items.active {
  background-color: var(--background-color);
  color: var(--gray-primary-color);
}

/* Styles for table */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  border-radius: 5px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

tbody {
  font-size: 13px;
}

th,
td {
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
}

th {
  color: #333;
}

tr {
}

tr:hover {
}

/* Zebra stripe for table rows */
tbody tr:nth-of-type(odd) {
}

tbody tr:last-of-type {
}

/* Active state for tab items */
