.offers_slider {
  margin-bottom: 30px;
}

.slider_header_title {
  font-size: 17px;
}

.slider_header_description {
  font-size: 12px;
  color: var(--orange-darkmode);
}

.recommend_network {
  margin-bottom: 30px;
}

.networks_header_title {
  font-size: 17px;
}

.networks_header_description {
  font-size: 12px;
  color: var(--border-color);
}

.all_network {
  margin-bottom: 30px;
}

.network_list {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}

.networks_card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 190px;
  height: 80px;
  background-color: #2a292f;
  margin-bottom: 15px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.networks_card:hover {
  transition: 0.5s ease-in-out;
  transform: scale(1.1);
  background-color: var(--green-primary-color);
}

.card_note {
  display: flex;
  font-size: 10px;
}

.card_note .bonus {
  position: absolute;
  left: 0px;
  top: 2px;
  color: var(--background-color);
  width: 20%;
  font-size: 11px;
  font-weight: 600;
  background-color: var(--green-primary-color);
  border-radius: 5px 5px 5px 0px;
  padding: 3px;
}

.card_note .rating {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 80%;
  display: flex;
  justify-content: end;
  padding-right: 10px;
}

.card_note .rating img {
  width: 11px;
}

.card_note .rating span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_logo img {
  width: 100%;
}

.survey_network {
  margin-bottom: 30px;
}

.recommended_card {
  font-family: "Luxora Grotesk Book";
  cursor: pointer;
  position: relative;
  width: 130px;
  height: 220px;
  background: #313131;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease-in-out;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.recommended_bonus {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--background-color);
  width: 35px;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--green-primary-color);
  border-radius: 5px 5px 5px 5px;
  padding: 3px;
}

.recommended_lock {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--background-color);
  width: 90px;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--orange-darkmode);
  border-radius: 5px 5px 5px 5px;
  padding: 3px;
}
.recommended_soon {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--background-color);
  width: 90px;
  font-size: 12px;
  font-weight: 600;
  background-color: #979797;
  border-radius: 5px 5px 5px 5px;
  padding: 3px;
}

.network_img {
  position: absolute;
  transition: 0.2s ease-in-out;
  z-index: 1;
}

.textBox {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}

.textBox > .text {
  font-weight: bold;
}

.textBox > .head {
  font-size: 20px;
}

.textBox > .price {
  font-size: 17px;
}

.textBox > .button {
  font-size: 10px;
}

.button_play {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2aec7b62;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 12px;
  color: lightgrey;
}

.recommended_rating {
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: 10%;
}

.recommended_network_name {
  font-size: 15px;
  font-weight: 400;
  position: absolute;
  bottom: 20%;
}

.recommended_card:hover > .textBox {
  opacity: 1;
}

.recommended_card:hover > .network_img {
  filter: blur(7px);
  animation: anim 3s infinite;
}

.recommended_card:hover > .recommended_rating {
  filter: blur(7px);
  animation: anim 3s infinite;
}

.recommended_card:hover > .recommended_bonus {
  opacity: 1;
  filter: blur(7px);
  animation: anim 3s infinite;
}

@keyframes anim {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0);
  }
}

.card:hover {
  transform: scale(1.04);
}

.mmwall {
  background: hsla(61, 90%, 54%, 0.1);

  background: linear-gradient(
    90deg,
    hsla(61, 90%, 54%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(61, 90%, 54%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(61, 90%, 54%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F31F", endColorstr="#D8DED6", GradientType=1);
}

.adgatemedia {
  background: hsla(199, 82%, 56%, 0.1);

  background: linear-gradient(
    90deg,
    hsla(199, 82%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(199, 82%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(199, 82%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F31F", endColorstr="#D8DED6", GradientType=1);
}

.lootably {
  background: hsla(7, 79%, 59%, 0.1);

  background: linear-gradient(
    90deg,
    hsla(7, 79%, 59%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(7, 79%, 59%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(7, 79%, 59%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F31F", endColorstr="#D8DED6", GradientType=1);
}

.torox {
  background: hsla(298, 79%, 59%, 0.1);

  background: linear-gradient(
    90deg,
    hsla(298, 79%, 59%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(298, 79%, 59%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(298, 79%, 59%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F31F", endColorstr="#D8DED6", GradientType=1);
}

.notik {
  background: hsla(202, 73%, 54%, 0.1);

  background: linear-gradient(
    90deg,
    hsla(202, 73%, 54%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(202, 73%, 54%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(202, 73%, 54%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F31F", endColorstr="#D8DED6", GradientType=1);
}

.revenue {
  background: hsla(239, 87%, 56%, 0.137);

  background: linear-gradient(
    90deg,
    hsla(239, 87%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(239, 87%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(239, 87%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F31F", endColorstr="#D8DED6", GradientType=1);
}

.hangmyads {
  background: hsla(283, 87%, 56%, 0.1);

  background: linear-gradient(
    90deg,
    hsla(283, 87%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(283, 87%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(283, 87%, 56%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F31F", endColorstr="#D8DED6", GradientType=1);
}

.bitlabs {
  background: hsla(230, 93%, 37%, 0.1);

  background: linear-gradient(
    90deg,
    hsla(230, 93%, 37%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(230, 93%, 37%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(230, 93%, 37%, 0.1) 0%,
    hsla(105, 11%, 85%, 0.1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F0F31F", endColorstr="#D8DED6", GradientType=1);
}
