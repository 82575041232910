.authen {
  cursor: pointer;
  position: fixed;
  width: 50px;
  height: 65px;
  bottom: 0px;
  left: 20px;
  background-color: var(--green-primary-color);
  opacity: 0.5;
  border-radius: 40px 40px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authen.open {
  z-index: 999;
}

.authen ion-icon {
  color: var(--background-color);
  font-size: 30px;
}

.authen:hover {
  opacity: 1;
  transition: 0.5s;
}

.authen .text {
  color: var(--background-color);
  font-size: 12px;
  font-weight: 600;
}

/* Modal */
.modal {
  font-family: "Luxora Grotesk Book";
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  /* Blur effect */
}

/* Modal Content */
.modal-content {
  position: relative;
}

/* Close button (X) */
.close-button {
  color: #151717;
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

/* Login Form */
.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--green-primary-color);
  padding: 30px;
  width: 450px;
  border-radius: 20px;
}

::placeholder {
}

.form button {
  align-self: flex-end;
}

.flex-column > label {
  color: #151717;
  font-weight: 600;
}

.inputForm {
  font-family: "Luxora Grotesk Book";
  border: 1.5px solid var(--background-color);
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.input {
  background-color: var(--green-primary-color);
  padding: 5px;
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 85%;
}

.input:focus {
  outline: none;
}

.inputForm:focus-within {
  border: 1.5px solid #2d79f3;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.flex-row > div > label {
  font-size: 14px;
  color: black;
  font-weight: 400;
}

.span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}

.button-submit {
  font-family: "Luxora Grotesk Book";
  margin: 20px 0 10px 0;
  background-color: #151717;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}

.button-submit:hover {
  background-color: #252727;
}

.p {
  text-align: center;
  color: black;
  font-size: 14px;
  margin: 5px 0;
}

.btn {
  font-family: "Luxora Grotesk Book";
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.btn:hover {
  border: 1px solid #2d79f3;
}

.error-message {
  text-align: center;
  color: red;
}
