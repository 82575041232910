:root {
  --green-primary-color: #2aec7c;
  --gray-primary-color: #f8f8f8;
  --background-color: #2a292f;
  --background-dark-color: #3d3d45;
  --orange-darkmode: #f39c12;
  --text-title-color: #2a292f;
  --text-description-color: #414141;
  --border-color: #bbbbbb;
  --footer-color: #1a1a1a;
  --app-text-title-color: #ffffff;
}

::-webkit-scrollbar {
  border-radius: 20px;
  width: 6px;
  height: 4px;
  background: var(--border-color);
}

::-webkit-scrollbar {
  border-radius: 20px;
  width: 6px;
  height: 4px;
  background: var(--border-color);
}

::-webkit-scrollbar-thumb {
  background: var(--text-description-color);
  border-radius: 15px;
}

.app {
  color: var(--app-text-title-color);
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: var(--green-primary-color);
}

.app.dark {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  background-color: var(--gray-primary-color);
}

.app_header {
  height: 140px;
}

.info_network span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app_content {
  margin: 20px 50px 20px 50px;
  padding: 20px 40px 20px 40px;
  border-radius: 15px;
  background-color: var(--footer-color);
}

.app_content.dark {
  background-color: var(--background-dark-color);
}
nav {
  display: none;
}
