/* Font-face for Luxora Grotesk Bold */
@font-face {
  font-family: "Luxora Grotesk Bold";
  src: url("/public/assets/font/LuxoraGrotesk-Bold.woff") format("woff");
}

/* Font-face for Luxora Grotesk Bold Italic */
@font-face {
  font-family: "Luxora Grotesk Bold Italic";
  src: url("/public/assets/font/LuxoraGrotesk-BoldItalic.woff") format("woff");
}

/* Font-face for Luxora Grotesk Book */
@font-face {
  font-family: "Luxora Grotesk Book";
  src: url("/public/assets/font/LuxoraGrotesk-Book.woff") format("woff");
}

/* Font-face for Luxora Grotesk Book Italic */
@font-face {
  font-family: "Luxora Grotesk Book Italic";
  src: url("/public/assets/font/LuxoraGrotesk-BookItalic.woff") format("woff");
}

/* Font-face for Luxora Grotesk Heavy */
@font-face {
  font-family: "Luxora Grotesk Heavy";
  src: url("/public/assets/font/LuxoraGrotesk-Heavy.woff") format("woff");
}

/* Font-face for Luxora Grotesk Heavy Italic */
@font-face {
  font-family: "Luxora Grotesk Heavy Italic";
  src: url("/public/assets/font/LuxoraGrotesk-HeavyItalic.woff") format("woff");
}

/* Font-face for Luxora Grotesk Italic */
@font-face {
  font-family: "Luxora Grotesk Italic";
  src: url("/public/assets/font/LuxoraGrotesk-Italic.woff") format("woff");
}

/* Font-face for Luxora Grotesk Light */
@font-face {
  font-family: "Luxora Grotesk Light";
  src: url("/public/assets/font/LuxoraGrotesk-Light.woff") format("woff");
}

/* Font-face for Luxora Grotesk Light Italic */
@font-face {
  font-family: "Luxora Grotesk Light Italic";
  src: url("/public/assets/font/LuxoraGrotesk-LightItalic.woff") format("woff");
}

/* Font-face for Luxora Grotesk Medium */
@font-face {
  font-family: "Luxora Grotesk Medium";
  src: url("/public/assets/font/LuxoraGrotesk-Medium.woff") format("woff");
}

/* Font-face for Luxora Grotesk Medium Italic */
@font-face {
  font-family: "Luxora Grotesk Medium Italic";
  src: url("/public/assets/font/LuxoraGrotesk-MediumItalic.woff") format("woff");
}

/* Font-face for Luxora Grotesk Regular */
@font-face {
  font-family: "Luxora Grotesk Regular";
  src: url("/public/assets/font/LuxoraGrotesk-Regular.woff") format("woff");
}

/* Font-face for Luxora Grotesk Thin */
@font-face {
  font-family: "Luxora Grotesk Thin";
  src: url("/public/assets/font/LuxoraGrotesk-Thin.woff") format("woff");
}

/* Font-face for Luxora Grotesk Thin Italic */
@font-face {
  font-family: "Luxora Grotesk Thin Italic";
  src: url("/public/assets/font/LuxoraGrotesk-ThinItalic.woff") format("woff");
}

:root {
  --green-primary-color: #2aec7c;
  --gray-primary-color: #f8f8f8;
  --background-color: #2a292f;
  --background-dark-color: #3d3d45;
  --orange-darkmode: #f39c12;
  --text-title-color: #2a292f;
  --text-description-color: #414141;
  --border-color: #bbbbbb;
  --footer-color: #1a1a1a;
  --background-app: #1a1818;
}

a {
  color: inherit;

  text-decoration: none;
}

.main {
  font-family: "Luxora Grotesk Book";
  background-color: var(--background-color);
}

.main.dark {
  font-family: "Luxora Grotesk Book";
  background-color: var(--background-dark-color);
}

.topbar {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.topbar .topbar-left {
  display: flex;
  align-items: center;
  width: 50%;
  margin-left: 50px;
}

.topbar .topbar-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-right: 50px;
}

.topbar-left .topbar_logo {
  width: 100%;
}

.topbar_switch .checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: var(--gray-primary-color);
  width: 35px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fa-moon {
  font-size: 12px;
  color: var(--background-color);
}

.fa-sun {
  font-size: 12px;
  color: var(--orange-darkmode);
}

.checkbox-label .ball {
  background-color: var(--background-color);
  width: 18px;
  height: 18px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}

.topbar_action .btn_action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 25px;
  background-color: var(--background-color);
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray-primary-color);
  text-align: center;
  color: var(--gray-primary-color);
  cursor: pointer;
}

.topbar_action .btn_action :hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 25px;
  background-color: var(--gray-primary-color);
  border-radius: 20px;
  border-width: 1px;
  color: var(--background-color);
}

.btn_action span {
  font-size: 12px;
  font-weight: 500;
}

.container {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: var(--background-color);
}

.container.dark {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: var(--background-dark-color);
}

.banner {
  height: 818px;
  background-color: var(--green-primary-color);
  background-image: url(/public/assets/images/background_banner.png);
  background-repeat: no-repeat;
  background-position: center;

  background-size: contain;
}

.banner.dark {
  height: 818px;

  background-color: var(--gray-primary-color);
  background-image: url(/public/assets/images/background_banner.png);
  background-repeat: no-repeat;
  background-position: center;

  background-size: contain;
}

.topmenu {
  border-bottom: 1px solid var(--border-color);
}

.topmenu_content {
  margin-left: 50px;
  margin-right: 50px;
  height: 70px;
  display: flex;
}

.topmenu .topmenu_logo {
  display: flex;
  align-items: center;
  width: 15%;
}

.topmenu .topmenu_nav {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.topmenu .topmenu_bonus {
  position: absolute;
  top: 0px;
  right: -5px;
  border-radius: 5px;
  padding: 2px;
  font-size: 10px;
  font-weight: 500;
  color: #f8f8f8;
  background-color: #2a292fc0;
}

.nav_item {
  border-radius: 5px 5px 0px 0px;
  position: relative;
  font-weight: 600;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.5s ease-out;

  /* Dòng dưới cùng khi không hoạt động */
  text-decoration: none;
  /* Loại bỏ gạch chân của thẻ link */
}

.nav_item span {
  text-transform: uppercase;
  font-size: 0.875em;
  color: var(--background-app);
  /* Màu của văn bản */
  transition: color 0.3s ease-out;
}

.nav_item .mline {
  position: absolute;
  bottom: 0;
  left: 50%;
  /* Bắt đầu từ giữa */
  transform: translateX(-50%);
  /* Di chuyển ngược lại 50% chiều rộng của chính nó để căn giữa */
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #05050560 0%, #7e7e7e 50%, #05050560 100%);
  /* Gradient từ giữa ra hai bên */
  box-shadow: 0 0 1em #9d9e9e;
  transition: width 0.3s ease;
}

.nav_item.active,
.nav_item:hover {
}

.nav_item.active span,
.nav_item:hover span {
  /* Màu chữ khi mục đang được chọn hoặc di chuột qua */
}

.nav_item.active .mline,
.nav_item:hover .mline {
  z-index: 10;
  width: 100%;
  background: var(--background-app);
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  /* Dòng dưới cùng sẽ mở rộng ra khi mục đang được chọn hoặc di chuột qua */
}

.nav_item .underline {
  width: 0%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: rgb(111, 252, 186);
  z-index: 9;
  /* Đặt dưới .line */
}

.nav_item:hover .underline {
  width: 100%;
  /* Mở rộng ra khi di chuột qua */
}

.nav_item.active .underline {
  width: 100%;
  /* Mở rộng ra khi di chuột qua */
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.topmenu .button_menu {
  width: 35%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button_menu .dropdown_menu {
  width: 120px;
  height: 20px;
  background-color: transparent;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--background-color);
  text-align: center;
  color: var(--background-color);
  cursor: pointer;
}

.button_menu .dropdown_menu:hover {
  background-color: var(--background-color);
  color: var(--gray-primary-color);

  transition: 0.5s;
}

.button_menu .action_auth {
  margin-left: 10px;
  width: 90px;
  height: 20px;
  background-color: transparent;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--background-color);
  text-align: center;
  color: var(--background-color);
  cursor: pointer;
}

.button_menu .action_auth:hover {
  background-color: var(--background-color);
  color: var(--gray-primary-color);

  transition: 0.5s;
}

.action_auth span {
  font-size: 12px;
  font-weight: 600;
}

.dropdown_menu span {
  font-size: 12px;
  font-weight: 600;
}

.user-box {
  width: 35%;
  height: 60px;
  margin: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-avatar {
  cursor: pointer;
  width: 60px;
  height: 60px;
  background-color: rgba(33, 177, 93, 0.349);
  border-radius: 5px 0px 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-avatar img {
  width: 45px;
  height: 45px;
  border-radius: 10px;
}

.user-info {
  cursor: pointer;
  width: 120px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  background-color: rgba(33, 177, 93, 0.349);
}

.user-info .user-name {
  max-width: 90px;

  font-size: 14px;
  font-weight: 600;
  color: var(--background-app);
  white-space: nowrap;

  overflow: hidden;

  text-overflow: ellipsis;
}

.user-info .user-coin-box {
  max-width: 90px;
  color: var(--background-app);
  font-weight: 600;
  display: flex;
  align-items: center;
  background-color: #21b15d;
  border-radius: 5px;
}

.user-coin {
  font-size: 14px;
  padding-left: 5px;
}

.coin-icon img {
  padding-left: 2px;
  margin: 2px;
  display: flex;
  width: 16px;
}

.user-button {
  color: var(--background-app);
  height: 100%;
  border-radius: 0 10px 10px 0;
  background-color: #21b15d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  transition: width 1s linear;
}

.user-button:hover {
  background-color: #1c9b51;
}

.user-button.expanded {
  border-radius: 0px 10px 10px 0px;
  width: 140px;
  z-index: 999;
  display: grid;

  grid-template-columns: 1fr 1fr;

  grid-template-rows: repeat(2, 1fr);

  grid-gap: 1px;

  justify-items: center;

  align-items: center;

  cursor: pointer;
  transition: width 1s linear;
}

.btn_expan {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background-color: #4bd384;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  height: 20px;
}

.btn_expan:hover {
  background-color: var(--background-app);
  color: var(--gray-primary-color);
}

.signout {
  background-color: #e92929a1;
}

.banner_content {
  display: flex;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
}

.banner_leftside {
  width: 40%;
}

.left_headline {
  font-family: "Luxora Grotesk Book";
  font-weight: 500;
}

.primary_title {
  font-size: 62px;
  color: var(--text-title-color);
}

.left_headline .description {
  font-size: 40px;
  color: var(--text-description-color);
}

.about {
  margin-top: 80px;
}

.about_description {
  width: 400px;
  font-size: 15px;
}

.asktojoin {
  margin-top: 30px;
}

.button_earnnow {
  font-family: "Luxora Grotesk Medium";
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 30px;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid var(--background-color);
  text-align: center;
  color: var(--background-color);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.button_earnnow:hover {
  background-color: var(--background-color);
  color: var(--gray-primary-color);

  transform: scale(1.03);

  transition: 0.5s;
}

.button_earnnow ion-icon {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 20px;
}

.arrow {
  position: absolute;
  bottom: 50px;
  left: 100px;
}

.banner_middle {
  width: 60%;
}

.banner_rightside {
  width: 40%;
}
.bg_display {
  display: none;
}

.right_headline {
  font-family: "Luxora Grotesk Book";
  font-weight: 500;
}

.second_title {
  font-size: 40px;
  color: var(--text-title-color);
}

.second_description {
  font-size: 15px;
}

.button_needhelp {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 22px;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid var(--background-color);
  text-align: center;
  color: var(--background-color);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.button_needhelp:hover {
  background-color: var(--background-color);
  color: var(--gray-primary-color);
  transition: 0.5s;
}

.referral_title {
  margin-top: 50px;
}

.first_ref_title {
  font-size: 42px;
}

.second_ref_title {
  padding-left: 100px;
  font-size: 42px;
}

.percent {
  font-size: 89px;
  opacity: 0.25;
  animation: pulse 2s linear alternate infinite;
}

.scroll {
  margin-top: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll_container {
  position: relative;
  width: 24px;
  height: 24px;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: var(--background-color);
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

.scroll_text {
  font-family: "Luxora Grotesk Bold";
  display: block;
  margin-top: 75px;
  margin-left: -30px;
  font-size: 14px;
  color: var(--background-color);
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.25;
  animation: pulse 2s linear alternate infinite;
}

.howitwork {
  padding-left: 100px;
  padding-right: 100px;
  height: 450px;
  background-color: var(--gray-primary-color);
  margin-top: 5px;
}

.first_content {
  display: flex;
  height: 220px;
}

.big_content {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.big_content span {
  font-size: 62px;
  color: var(--text-title-color);
  padding-top: 60px;
}

.small_content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  width: 30%;
  border-bottom: 1px solid var(--border-color);

  border-left: 1px solid var(--border-color);
}

.small_content .numb {
  font-size: 62px;
  color: var(--border-color);
}

.small_content .title {
  font-size: 23px;
  color: var(--text-title-color);
}

.small_content .description {
  font-size: 15px;
  color: var(--text-description-color);
}

.second_content {
  display: flex;
  height: 220px;
}

.second_content .primary_content {
  width: 65%;

  border-left: 1px solid var(--border-color);

  background-color: var(--green-primary-color);
  padding-left: 30px;
  padding-top: 20px;
}

.second_content .sub_content {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.sub_content .numb {
  font-size: 62px;
  color: var(--border-color);
}

.sub_content .title {
  font-size: 23px;
  color: var(--text-title-color);
}

.sub_content .description {
  font-size: 15px;
  color: var(--text-description-color);
}

.primary_content .title {
  font-size: 62px;
  color: var(--text-title-color);
}

.primary_content .description {
  font-size: 15px;
  color: var(--text-description-color);
}

.primary_content .call_action {
  padding-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: var(--text-description-color);
}

.statistics {
  padding-left: 100px;
  padding-right: 100px;
  height: 470px;
  background-color: var(--gray-primary-color);
  margin-top: 5px;
}

.statis_head {
  text-align: center;
  padding-top: 30px;
}

.statis_head_title {
  font-size: 47px;
  color: var(--text-title-color);
}

.statis_head_description {
  font-size: 15px;
  color: var(--text-description-color);
}

.statis_analytics {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
}

.analytic_items {
  width: 60%;
  background-color: #c4c2c2;
  justify-self: center;

  align-self: center;

  text-align: center;

  padding: 20px;
  border-radius: 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.analytic_items .title {
  font-size: 26px;
}

.analytic_items .numb {
  font-size: 50px;
}

.whyus {
  background-color: var(--gray-primary-color);
}

.hugerewards {
  padding-left: 100px;
  padding-right: 100px;

  background-color: var(--gray-primary-color);
  margin-top: 5px;
  display: flex;
}

.hugerewards .left_rewards {
  width: 65%;
  position: relative;
}

.gitf_group {
  background-color: #2aec7c;
  position: absolute;
  left: 200px;
  top: 100px;
}

.rewardcard_01 {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 3;
  -webkit-animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
  animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
}

.rewardcard_02 {
  position: absolute;
  left: 50px;
  top: 90px;
  z-index: 2;
  -webkit-animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
  animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
}

.rewardcard_03 {
  position: absolute;
  left: 100px;
  top: 180px;
  z-index: 1;
  -webkit-animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
  animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
}

.crypto_01 {
  position: absolute;
  left: 400px;
  top: 200px;
}

.tag_01 {
  position: absolute;
  left: 400px;
  top: 120px;
  -webkit-animation: _3_animation__GFw7l 9s steps(150) infinite alternate;
  animation: _1_animation__GFw7l 9s steps(150) infinite alternate;
}

.tag_02 {
  position: absolute;
  left: 680px;
  top: 150px;
  -webkit-animation: _3_animation__GFw7l 9s steps(150) infinite alternate;
  animation: _2_animation__GFw7l 9s steps(150) infinite alternate;
}

.tag_03 {
  position: absolute;
  left: 740px;
  top: 380px;
  -webkit-animation: _3_animation__GFw7l 9s steps(150) infinite alternate;
  animation: _3_animation__GFw7l 9s steps(150) infinite alternate;
}

.hugerewards .right_rewards {
  width: 35%;
  text-align: right;
}

.right_rewards .title {
  margin-top: 50px;
  font-size: 62px;
}

.right_rewards .description {
  margin-top: 20px;
  font-size: 15px;
}

.type_rewards {
  width: 100%;
  margin-top: 50px;
}

.type_items {
  width: 100%;
}

.type_items .header {
  display: flex;
  justify-content: end;
  align-items: center;
}

.type_items .header .method {
  font-size: 18px;
  color: var(--text-title-color);
  font-weight: 600;
}

.header .space {
  padding-left: 10px;
  color: var(--border-color);
}

.type_items .detail {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  padding-right: 20px;
  color: var(--text-description-color);
}

.ourpartner {
  margin-top: 30px;
  background-color: var(--gray-primary-color);
}

.partner_head {
  display: flex;
  margin-bottom: 30px;
  margin-left: 100px;
  margin-right: 100px;
}

.partner_title {
  width: 40%;
  font-size: 62px;
  color: var(--text-title-color);
  text-align: center;
}

.partner_description {
  width: 60%;
  font-size: 15px;
  color: var(--text-description-color);
  text-align: end;
}

.marquee-container {
  position: relative;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.1) 100%
  );
  /* background: linear-gradient(to right, rgba(33, 232, 113, 0.2) 0%, rgba(0, 0, 0, 0) 50%, rgba(33, 232, 113, 0.2) 100%); */
  padding-top: 10px;
  padding-bottom: 10px;
}

.marquee-box {
  overflow-x: hidden !important;
  position: relative;
}

.marquee {
  flex: 0 0 auto;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: marquee-scroll 60s linear 0s infinite;
  animation-play-state: running;
  animation-delay: 0s;
  animation-direction: normal;
  margin-bottom: 20px;
}

.marquee-reverse {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: marquee-scroll-reverse 150s linear 0s infinite;
  animation-play-state: running;
  animation-delay: 0s;
  animation-direction: normal;
}

.marquee_items {
  height: 68px;
  width: auto;
  background-color: #121e28;
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marquee_items img {
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  filter: grayscale(100%);
}

.trustpilot {
  background-color: var(--gray-primary-color);
  margin-top: 80px;
  height: 350px;
}

.trustpilot_container {
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rating {
  width: 30%;
}

.rating_container {
  margin-left: 100px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
  -webkit-box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
  -moz-box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
}

.rating_header {
  text-align: center;
  background-color: #00b67a;
}

.rating_header img {
  width: 50%;
}

.rating_detail {
  text-align: center;
}

.rating_detail .detail_title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

.star_group img {
  padding: 5px;
}

.detail_numb .numb {
  font-weight: 600;
  text-decoration: underline;
}

.reviews {
  width: 70%;
  display: flex;
}

.review_slide {
  width: 90%;
  margin-left: 100px;
}

.review_slide .slide {
  display: flex;
  gap: 30px;
}

.review_detail {
  width: 280px;
  height: 250px;
  background-color: #ffffff;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
  -webkit-box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
  -moz-box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
}

.review-detail.fade-out {
  opacity: 0;
}

.review-detail.fade-in {
  opacity: 1;
}

.items_header {
  padding: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.items_header .user_avatar {
  width: 40%;
  display: flex;
  justify-content: center;
}

.items_header .user_avatar img {
  width: 60px;
  border-radius: 50px;
}

.items_header .user_rating {
  display: flex;
  gap: 2px;
  width: 60%;
}

.items_header .user_rating img {
  width: 22px;
}

.button_control {
  width: 10%;
}

.items_review {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.item_review_username {
  font-weight: 600;
  color: var(--text-title-color);
  margin-bottom: 5px;
}

.item_review_userreview {
  overflow: hidden;

  text-overflow: ellipsis;

  font-size: 14px;
  color: var(--text-description-color);
  display: inline-block;
}

.button_control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.slide_btn {
  background-color: var(--green-primary-color);
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
  -webkit-box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
  -moz-box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
}

.slide_btn:hover {
  background-color: var(--background-color);
  color: var(--gray-primary-color);

  transform: scale(1.03);

  transition: 0.5s;
}

.slide_btn ion-icon {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.slide-transition {
  transition: transform 0.3s ease-in-out;
}

.worldmap {
  background-color: #121115;
}

.map_container {
  margin-left: 100px;
  margin-right: 100px;
}

.map_header {
  padding-top: 50px;
  display: flex;
  color: var(--gray-primary-color);
}

.map_header_title {
  width: 50%;
  font-size: 63px;
}

.map_header_description {
  width: 50%;
  margin-left: 500px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.map_header_description p {
  font-size: 20px;
}

.isolation {
  margin-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.isolation img {
  width: 80%;
}

.footer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: var(--footer-color);
}

.quicklink {
  width: 60%;
  padding-left: 50px;
  padding-right: 50px;
}

.quicklink_logo {
  padding-bottom: 20px;
}

.quicklink_menu {
  display: flex;
  align-items: center;
  gap: 40px;
  color: var(--border-color);
}

.link :hover {
  cursor: pointer;
  color: var(--gray-primary-color);
}

.sub_link {
  width: 13%;
}

.sublink_item {
  color: var(--border-color);
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
}

.sublink_item .item_icon {
  font-size: 28px;
}

.sublink_item .item_detail .detail_title {
  font-size: 14px;
}

.sublink_item .item_detail .detail_des {
  font-size: 11px;
}

.copyright {
  padding-left: 100px;
  color: var(--border-color);
  font-size: 12px;
}

.address {
  display: flex;
  align-items: center;
  justify-content: center;
}
