.commmunity_content {
  display: flex;
  justify-content: center;
  /* background-image: url("/public/assets/images/community_header.png");
    background-repeat: no-repeat;
    background-size: cover; */
}

.community_left_content {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.commmunity_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  background-color: transparent;
}

.ranking_detail {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ranking_detail_border {
  width: 50%;
  padding: 20px;
  background: linear-gradient(
    rgba(103, 248, 135, 0.17),
    rgba(217, 217, 217, 0)
  );
  border-radius: 10px;
  border: 1px dashed #59fb9a;
  font-size: 0.7rem;
}

.ranking_couter_time {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  margin-top: 10px;
  height: 155px;
}

.countdown {
  width: 100%;
  /* Giảm xuống một nửa */
  margin: 0 auto;
}

.countdown .bloc-time {
  float: left;
  margin-right: 22.5px;
  text-align: center;
}

.countdown .bloc-time:last-child {
  margin-right: 0;
}

.countdown .count-title {
  display: block;
  margin-bottom: 7.5px;
  /* Giảm khoảng cách giữa tiêu đề và số */
  font-size: 0.47em;
  color: #f7f7f7;
  text-transform: uppercase;
}

.countdown .figure {
  position: relative;
  float: left;
  height: 55px;
  /* Giảm chiều cao xuống một nửa */
  width: 50px;
  /* Giảm chiều rộng xuống một nửa */
  margin-right: 5px;
  /* Giảm khoảng cách giữa các số */
  border-radius: 4px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 8px;
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
}

.countdown .figure:last-child {
  margin-right: 0;
}

.countdown .figure > span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 2.97em;
  font-weight: 700;
  color: #23b95f;
}

.countdown .figure .top:after,
.countdown .figure .bottom-back:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.countdown .figure .top {
  z-index: 3;
  background-color: #f7f7f7;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -moz-border-radius-topleft: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  -moz-transform: perspective(200px);
  -ms-transform: perspective(200px);
  -webkit-transform: perspective(200px);
  transform: perspective(200px);
}

.countdown .figure .bottom {
  z-index: 1;
}

.countdown .figure .bottom:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.02);
}

.countdown .figure .bottom-back {
  z-index: 2;
  top: 0;
  height: 50%;
  overflow: hidden;
  background-color: #f7f7f7;
  -moz-border-radius-topleft: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}

.countdown .figure .bottom-back span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.countdown .figure .top,
.countdown .figure .top-back {
  height: 50%;
  overflow: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.countdown .figure .top-back {
  z-index: 4;
  bottom: 0;
  background-color: #f7f7f7;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -moz-transform: perspective(200px) rotateX(180deg);
  -ms-transform: perspective(200px) rotateX(180deg);
  -webkit-transform: perspective(200px) rotateX(180deg);
  transform: perspective(200px) rotateX(180deg);
  -moz-border-radius-bottomleft: 10px;
  -webkit-border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -webkit-border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.countdown .figure .top-back span {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: auto;
}

.community_right_content {
  width: 60%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.ranking_status {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ranking_status .ranking {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ranking .ranking_image {
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ranking_status .ranking img {
  width: 15%;
}

.bottom_rank {
  font-family: "Luxora Grotesk Book";
  font-weight: 600;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.bottom_rank2 {
  border-radius: 5px 5px 0px 0px;
  color: var(--background-color);
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.3%;
  height: 60px;
  background: linear-gradient(180deg, #dee1e2, #b0bec5);
}

.bottom_rank img {
  width: 15px;
  padding-right: 5px;
}

.bottom_rank1 {
  border-radius: 5px 5px 0px 0px;
  color: var(--background-color);
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.3%;
  height: 80px;
  background: linear-gradient(180deg, #fff0b3, #fc0);
}

.bottom_rank3 {
  border-radius: 5px 5px 0px 0px;
  color: var(--background-color);
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33.3%;
  height: 50px;
  background: linear-gradient(180deg, #ffcd83, #ff9800);
}

.rank_text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-dark-color);
  padding: 10px 0px 10px 0px;
}

.rank_text_title {
  color: #ffaa00;
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
}

.rank_text_title img {
  width: 15px;
  padding-right: 5px;
}

.table {
  color: var(--gray-primary-color);
  width: 100%;
}

.table-header {
  display: flex;
  width: 100%;
  background: #000;
  padding: 18px 0;
}

.table-row {
  background: #727272;
  display: flex;
  width: 100%;
  padding: 12px 0;
}

.table-row:nth-of-type(odd) {
  background: #6e6c6cbb;
}

.table-data,
.header__item {
  flex: 1 1 20%;
  text-align: center;
}

.table-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-data img {
  width: 15px;
  padding-right: 5px;
}

.header__item {
  text-transform: uppercase;
}

.filter__link {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
}

.filter__link::after {
  content: "";
  position: absolute;
  right: -18px;
  color: white;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.filter__link.desc::after {
  content: "(desc)";
}

.filter__link.asc::after {
  content: "(asc)";
}
