.offers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.offer_items {
  cursor: pointer;
  position: relative;
  width: 105px;
  height: 180px;
  background-color: #2a292f;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.offer_items:hover {
  transform: scale(1.02);
  transition: 0.5s ease-out;
}

.offer_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offer_image img {
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 80px;
  height: 80px;
}

.offer_names {
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: 600;
}

.offer_description {
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  color: var(--border-color);
}

.offer_prize {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
}

.offer_prize img {
  width: 14px;
  padding-right: 5px;
}

.offer_network {
  position: absolute;
  bottom: 2px;
  right: 5px;
  /* Bạn có thể điều chỉnh padding và margin tại đây nếu cần */
  padding: 5px 0;
}

.offer_network img {
  width: 50px;
  vertical-align: middle;
  filter: brightness(0) invert(1);
  /* Điều chỉnh vị trí dọc của ảnh */
}

.offer_platform {
  position: absolute;
  padding: 5px;
  top: 0px;
  right: 0px;
  border-radius: 0px 10px 0px 10px;
  background-color: #2a292fc0;
}

.offer_platform img {
  padding-right: 3px;
  width: 15px;
}

.skeleton-shimmer {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    90deg,
    rgba(75, 74, 74, 0.075) 25%,
    rgba(136, 135, 135, 0.048) 50%,
    rgba(92, 91, 91, 0.021) 75%
  );
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
