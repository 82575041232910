/* Nền mờ cho modal */
.modalOfferAPI-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  /* Màu đen với độ trong suốt 50% */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Khung chính của modal */
.modalOfferAPI-content {
  position: relative;
  color: var(--border-color);
  width: 80%;
  max-width: 600px;
  /* Giới hạn chiều rộng tối đa cho modal */
  background-color: var(--background-color);
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Bóng mềm */
  z-index: 1001;
}

.modalOfferAPI-header {
  height: 20px;
}

/* Tiêu đề của modal */
.modalOfferAPI-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Nội dung của modal */
.modalOfferAPI-body {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Nút đóng modal */
.modalOfferAPI-close {
  color: var(--border-color);
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.main_offerinfo {
  display: flex;
  align-items: center;
}

.offer_img {
  margin-right: 30px;
}

.offer_img img {
  width: 100px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.offer_detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--footer-color);
  width: 70%;
}

.offer_detail_name {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.offer_detail_about {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.detail_network {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.detail_network_image img {
  width: 50px;
}

.detail_platform_image {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.offer_detail_platform {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.offer_detail_platform img {
  width: 20px;
}

.detail_platform_text {
  font-size: 10px;
}

.detail_network_text {
  font-size: 10px;
}

.offer_detail_payout {
  text-align: center;
}

.detail_payout_text {
  font-size: 10px;
}

.detail_payout_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offer_detail_payout img {
  width: 20px;
  padding-right: 10px;
}

.offer_detail_description {
  font-size: 13px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--footer-color);
}

.detail_description_title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.start_offer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.start_offer a {
  border-radius: 5px;
  color: var(--background-color);
  font-weight: 600;
  text-align: center;
  width: 100%;
  padding: 10px;
  background-color: var(--green-primary-color);
}
