.earnfeed {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  padding: 5px;
  overflow: hidden;
}

.earnfeed .timeline_earn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.timeline_earn .timeline_item {
  cursor: pointer;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background-color: var(--background-app);
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_item .timeline_item_left {
  width: 25%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_item .timeline_item_left .avatar img {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.timeline_item .timeline_item_right {
  width: 75%;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeline_item .timeline_item_right .notify {
  margin-left: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.timeline_item .timeline_item_right .notify .username {
  display: flex;
  align-items: center;
  justify-content: start;
  color: var(--border-color);
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeline_item .timeline_item_right .notify .username span {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeline_item .timeline_item_right .notify .earntime {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 10px;
  color: #999797;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeline_item .timeline_item_right .notify .earntime span {
  font-size: 10px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeline_item .timeline_item_right .info {
  width: 100%;
  display: flex;
  align-items: center;
}

.timeline_item .timeline_item_right .info .info_coins {
  background-color: #2a292f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 5px;
}

.timeline_item .timeline_item_right .info .info_coins .coins_icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_item .timeline_item_right .info .info_coins .coins_icon img {
  width: 12px;
  height: 12px;
}

.timeline_item .timeline_item_right .info .info_coins .coins_number {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_item .timeline_item_right .info .info_coins .coins_number span {
  font-size: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline_item .timeline_item_right .info .info_network {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 5px;
  color: var(--border-color);
  font-size: 10px;
}

/* CSS */
.tooltip {
  --bs-tooltip-zindex: 9999;
  --bs-tooltip-max-width: 500px;
  --bs-tooltip-padding-x: 10px;
  --bs-tooltip-padding-y: 10px;
  --bs-tooltip-font-size: 12px;
  --bs-tooltip-border-radius: 5px;
  --bs-tooltip-opacity: 0.97;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  position: relative;
  font-family: "Luxora Grotesk Book";

  /* Đặt position là relative để tạo tooltip pseudo-element */
}

.tooltip::before {
  /* Pseudo-element để tạo mũi tên */
  content: "";
  position: absolute;
  bottom: 100%;
  /* Đặt ở phía trên của tooltip */
  left: 50%;
  /* Đặt giữa tooltip */
  margin-left: -5px;
  /* Độ dời ngang để canh giữa */
  border-width: 5px;
  /* Độ dày của mũi tên */
  border-style: solid;
  border-color: transparent transparent #0e121a transparent;
  /* Màu sắc của mũi tên */
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  width: 100%;
  max-height: 50px;
  text-align: center;
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: #2a292f;
  border-radius: var(--bs-tooltip-border-radius);
}

/* CSS */
.tooltip-table {
  display: flex;
  flex-direction: column;
  /* Xếp các cột theo chiều dọc */
  align-items: flex-start;
  /* Căn trái các cột */
  width: 100%;
}

.tooltip-row {
  display: flex;
  flex-direction: row;
  /* Xếp các phần tử trong hàng theo chiều ngang */
  align-items: center;
  /* Căn giữa các phần tử trong hàng theo chiều dọc */
  justify-content: space-between;
  /* Các cột sẽ căn cách đều nhau */
  width: 100%;
  margin-bottom: 8px;
  /* Khoảng cách giữa các hàng */
}

.tooltip-caption {
  font-size: 12px;
  font-weight: 600;
  color: var(--orange-darkmode);
  flex-basis: 30%;
  /* Độ rộng của phần tử caption (để cố định chiều rộng của cột) */
  text-align: left;
  padding-right: 5px;
}

.tooltip-value {
  font-size: 12px;
  font-weight: 400;
  flex-basis: 70%;
  text-align: left;
  /* Độ rộng của phần tử value (để cố định chiều rộng của cột) */
  white-space: nowrap;
  /* Ngăn chữ xuống dòng */
  overflow: hidden;
  /* Ẩn nội dung dư thừa */
  text-overflow: ellipsis;
  /* Hiển thị dấu ba chấm (...) khi nội dung quá dài */
}

.timeline_item.new-item {
  background-color: var(--background-dark-color);
  animation: slideRight 0.8s ease-in-out;
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
