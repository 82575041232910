@media (min-width: 1023px) and (max-width: 1423px) {
  nav {
    display: block;
  }
  .topbar {
    display: none;
  }
  .container {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: var(--background-color);
  }
  .container.dark {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: var(--background-color);
  }

  .topmenu {
    border-bottom: 1px solid var(--border-color);
  }

  .topmenu_content {
    all: unset;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    height: 70px;
  }
  .topmenu .topmenu_logo {
    display: flex;
    align-items: center;
    width: 20%;
  }
  .topmenu .topmenu_nav {
    width: 80%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .user-box {
    width: 50%;
    height: 60px;
    margin: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .banner {
    height: 420px;
    background-color: var(--green-primary-color);
    background-image: none;
    background-repeat: no-repeat;
    background-position: center;
  }
  .banner.dark {
    height: 420px;
    background-color: var(--green-primary-color);
    background-image: none;
    background-repeat: no-repeat;
    background-position: center;
  }
  .bg_display {
    display: block;
    width: 250px;
    position: absolute;
    top: 170px;
    right: 10px;
  }

  .banner_content {
    display: flex;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: space-between;
  }

  .banner_leftside {
    width: 30%;
  }
  .banner_rightside {
    width: 65%;
  }
  .banner_middle {
    width: 0%;
  }
  .primary_title {
    font-size: 30px;
    color: var(--text-title-color);
  }
  .left_headline .description {
    font-size: 18px;
    color: var(--text-description-color);
  }
  .about {
    margin-top: 30px;
  }

  .about_description {
    width: 400px;
    font-size: 13px;
  }

  .second_title {
    font-size: 20px;
    color: var(--text-title-color);
  }
  .second_description {
    font-size: 13px;
  }

  .referral_title {
    display: none;
  }
  .banner_middle {
    display: none;
  }

  .scroll {
    margin-top: -55px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button_earnnow {
    font-size: 12px;
    width: 150px;
  }
  .needhelp {
  }
  .howitwork {
    padding-left: 5px;
    padding-right: 5px;

    background-color: var(--gray-primary-color);
    margin-top: 5px;
  }
  .first_content {
    display: flex;
  }
  .second_content {
    display: flex;
  }
  .big_content {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
  }
  .big_content span {
    font-size: 30px;
    color: var(--text-title-color);
    padding-top: 60px;
  }
  .small_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    width: 30%;
    border-bottom: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
  }
  .small_content .numb {
    font-size: 30px;
    color: var(--border-color);
  }
  .small_content .title {
    font-weight: 600;
    font-size: 15px;
    color: var(--text-title-color);
  }
  .small_content .description {
    font-size: 12px;
    color: var(--text-description-color);
  }
  .sub_content .numb {
    font-size: 62px;
    color: var(--border-color);
  }
  .sub_content .numb {
    font-size: 30px;
    color: var(--border-color);
  }
  .sub_content .title {
    font-weight: 600;
    font-size: 15px;
    color: var(--text-title-color);
  }
  .sub_content .description {
    font-size: 13px;
    color: var(--text-description-color);
  }
  .primary_content .title {
    font-size: 30px;
    color: var(--text-title-color);
  }
  .primary_content .description {
    font-size: 13px;
    color: var(--text-description-color);
  }
  .arrow {
    display: none;
  }
  .statistics {
    padding-left: 5px;
    padding-right: 5px;
    height: 300px;
    background-color: var(--gray-primary-color);
    margin-top: 5px;
  }
  .statis_analytics {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
  }
  .statis_head_title {
    font-size: 30px;
    color: var(--text-title-color);
  }
  .analytic_items {
    width: 80%;
    background-color: #c4c2c2;
    justify-self: center;
    align-self: center;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }
  .analytic_items .title {
    font-size: 15px;
  }
  .analytic_items .numb {
    font-size: 30px;
  }
  .hugerewards {
    padding-left: 5px;
    padding-right: 5px;
    height: 500px;
    background-color: var(--gray-primary-color);
    margin-top: 5px;
    display: block;
  }
  .right_rewards .title {
    margin-top: 2px;
    font-size: 30px;
  }
  .right_rewards .title span {
  }

  .hugerewards .left_rewards {
    width: 50%;
    position: relative;
  }
  .gitf_group {
    background-color: #2aec7c;
    position: absolute;
    left: 10px;
    top: -50px;
  }
  .rewardcard_01 {
    width: 100px;
    position: absolute;
    left: 10px;
    top: 200px;
    z-index: 3;
    animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
  }
  .rewardcard_02 {
    width: 100px;
    position: absolute;
    left: 60px;
    top: 230px;
    z-index: 2;
    animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
  }
  .rewardcard_03 {
    width: 100px;
    position: absolute;
    left: 100px;
    top: 260px;
    z-index: 1;
    animation: qrAnimation__3F3wl 2s steps(60) infinite alternate;
  }
  .crypto_01 {
    width: 250px;
    position: absolute;
    left: 150px;
    top: 200px;
  }

  .hugerewards .right_rewards {
    width: 100%;
    text-align: right;
  }
  .right_rewards .description {
    margin-top: 20px;
    font-size: 15px;
  }
  .type_rewards {
    width: 100%;
    margin-top: 10px;
  }
  .type_items .header .method {
    font-size: 15px;
    color: var(--text-title-color);
    font-weight: 600;
  }
  .type_items .detail {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    padding-right: 20px;
    color: var(--text-description-color);
  }
  .tag_group {
    display: none;
  }

  .partner_head {
    display: flex;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .partner_title {
    width: 40%;
    font-size: 30px;
    color: var(--text-title-color);
    text-align: center;
  }
  .partner_description {
    width: 60%;
    font-size: 13px;
    color: var(--text-description-color);
    text-align: end;
  }
  .marquee_items {
    height: 30px;
    width: auto;
    background-color: #121e28;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .marquee_items img {
    width: 60px;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    border: none;
    filter: grayscale(100%);
  }
  .trustpilot {
    background-color: var(--gray-primary-color);
    margin-top: 30px;
    height: 250px;
  }
  .trustpilot_container {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rating {
    width: 100%;
  }
  .rating_container {
    margin-left: 0px;
    height: 200px;
    background-color: #ffffff;
    box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
    -webkit-box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
    -moz-box-shadow: 29px 23px 32px 3px rgba(176, 172, 172, 0.38);
  }
  .rating_header img {
    width: 30%;
  }
  .reviews {
    display: none;
  }
  .map_container {
    margin-left: 5px;
    margin-right: 5px;
  }
  .map_header_title {
    width: 50%;
    font-size: 30px;
  }
  .map_header_description {
    display: none;
  }
  .quicklink {
    display: none;
  }
  .sub_link {
    display: none;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    background-color: var(--footer-color);
  }
  .sublink_item {
    display: none !important;
  }

  .authen {
    z-index: 9998;
    cursor: pointer;
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 65px;
    left: 5px;
    background-color: var(--green-primary-color);
    opacity: 1;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .authen .text {
    display: none;
  }

  .app {
    color: var(--app-text-title-color);
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    background-color: var(--green-primary-color);
  }
  .app.dark {
    color: var(--app-text-title-color);
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    background-color: var(--green-primary-color);
  }

  .app_content {
    margin: 0px 0px 0px 0px;
    padding: 5px 5px 5px 5px;
    border-radius: 0px;
    background-color: var(--footer-color);
  }
  .network_list {
    display: flex;
    align-items: center;
    gap: 10px !important;
    margin-top: 20px;
    overflow-x: scroll;
  }

  .recommended_card {
    min-width: 100px !important;
    width: 130px !important;
    height: 190px !important;
    margin: 5px;
  }

  .network_img {
    width: 7rem;
    position: absolute;
    transition: 0.2s ease-in-out;
    z-index: 1;
  }

  .card_logo img {
    width: 5rem !important;
  }

  ::-webkit-scrollbar {
    width: 0; /* Đặt chiều rộng của thanh cuộn là 0 */
    height: 0; /* Đặt chiều cao của thanh cuộn là 0 (với thanh cuộn ngang) */
  }
  .recommended_network_name {
    font-size: 10px !important;
    font-weight: 400;
    position: absolute;
    bottom: 20%;
  }

  .networks_card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-width: 160px;
    width: 190px;
    height: 80px;
    background-color: #2a292f;
    margin-bottom: 15px;
    border-radius: 10px;
    backdrop-filter: none !important;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }
  .networks_card:hover {
    transition: 0.5s ease-in-out;
    transform: scale(1) !important;
    background-color: var(--green-primary-color);
  }

  .method_list {
    gap: 10px !important;
    grid-template-columns: repeat(5, 0fr) !important;
  }
  .card_method {
    max-width: 185px;
    width: 185px;
    height: 94px;
    margin-bottom: 15px;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: none !important;
  }

  .commmunity_content {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    gap: 20px !important;
  }
  .community_left_content {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px !important;
  }
  .ranking_detail_border {
    width: 90% !important;
  }
  .community_right_content {
    width: 100% !important;
  }
  .ranking_status .ranking img {
    width: 30% !important;
  }

  .bonus_content {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    gap: 50px;
  }
  .bonus_left {
    width: 100% !important;
  }

  .affiliate_link {
    display: flex;
    flex-direction: column;
    justify-content: none !important;
    align-items: start !important;
    height: 120px !important;
    background-color: #2a292fc0;
    border-radius: 10px;
    padding: 10px;
  }
  .bonus_right {
    width: 100% !important;
  }

  .daily_rewards {
    margin-top: 20px;
    align-items: center;
    height: 250px !important;
    background-color: #2a292fc0;
    border-radius: 10px;
    padding: 10px;
  }

  .streakbox {
    display: flex;
    padding: 10px;
    gap: 10px;
    overflow: scroll;
  }
  .box_items {
    cursor: pointer;
    min-width: 90px;
    width: 120px !important;
    height: 120px;
    border-radius: 10px;
    background-color: #2f2e33;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #3f3f3f;
  }
  .challengesbox {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    align-items: center;
    grid-gap: 10px;
    text-align: center;
    overflow: scroll;
  }
  .challengesbox_items {
    max-width: 150px;
  }

  .bonus_box {
    display: flex;
    flex-direction: column;
    height: 150px !important;
    font-size: 12px;
    background-color: #2a292fc0;
    border-radius: 10px;
    padding: 10px;
  }

  .btn_bonus {
    font-size: 12px !important;
  }
  .modal-content {
    width: 60%;
    position: relative;
  }
  .close-button {
    color: #151717;
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--green-primary-color);
    padding: 30px;
    width: auto !important;
    border-radius: 20px;
  }
  .wall-modals {
    color: var(--background-color);
    background-color: var(--green-primary-color);
    margin: 5% auto;
    padding-top: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 100% !important;
    height: 100% !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-modal .modal-content {
    color: var(--background-color);
    background-color: var(--green-primary-color);
    margin: 0px !important;
    padding: 0px !important;
    border: 1px solid #888;
    border-radius: 10px;
    width: 100% !important;
    height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
  }
  .user_history .tabs {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px !important;
  }
  .user_display_name {
    font-size: 15px !important;
    font-weight: 600;
  }
  .main_info {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-left: 5px;
  }
  .statis_text {
    font-size: 12px !important;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 12px !important;
    text-align: left;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  .crypto-modal {
    color: #e1e1e1;
    background-color: var(--background-color);
    margin: 5% auto;
    border-radius: 10px;
    width: 90% !important;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .gift-modal {
    color: #e1e1e1;
    background-color: var(--background-color);
    margin: 5% auto;
    border-radius: 10px;
    width: 90% !important;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .splide__arrow--prev {
    left: 0px !important;
    /* Đặt khoảng cách bên trái là 30px */
  }

  .splide__arrow--next {
    right: 0px !important;
    /* Đặt khoảng cách bên phải là 30px */
  }
}
