/* CSS for the modal overlay */
.wall-modals-overlay {
  font-family: "Luxora Grotesk Book";
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}

/* CSS for the modal */
.wall-modals {
  color: var(--background-color);
  background-color: var(--green-primary-color);
  margin: 5% auto;
  padding-top: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: 50%;
  height: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* CSS for the modal content */
.wall-modal-content {
  flex: 1;
  padding-top: 20px;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* CSS for the iframe */
iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  display: block;
  /* Ensure the iframe is displayed when not loading */
}

.wall-modals .wall_modal_title {
  z-index: 999;
  margin-left: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

/* CSS for the close button */
.wall-modals .close {
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: var(--background-color);
}

.iframe-network {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

/* CSS for the loading spinner */
.loader_screen {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--green-primary-color);
  backdrop-filter: blur(5px);
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.loader,
.loader::before,
.loader::after {
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  animation: rotate 5s linear infinite;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  border-color: #5a4ff3;
}

.loader::before,
.loader::after {
  position: absolute;
  content: "";
}

.loader::before {
  border-color: #35a2d2;
  width: 110%;
  height: 110%;
  animation-delay: 0.5s;
}

.loader::after {
  border-color: #9c40fc;
  width: 120%;
  height: 120%;
  animation-delay: 0.1s;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
