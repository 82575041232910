@-webkit-keyframes qrAnimation__3F3wl {
  0% {
    transform: translateY(0) scale(1);
  }

  to {
    transform: translateY(-5%) scale(1.2);
  }
}

@keyframes qrAnimation__3F3wl {
  0% {
    transform: translateY(0) scale(1);
  }

  to {
    transform: translateY(-5%) scale(1.2);
  }
}

@-webkit-keyframes style_lightsRotate__2SfW0 {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  to {
    -webkit-transform: rotate(1turn) scale(1);
    transform: rotate(1turn) scale(1);
  }
}

@keyframes style_lightsRotate__2SfW0 {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }

  to {
    -webkit-transform: rotate(1turn) scale(1);
    transform: rotate(1turn) scale(1);
  }
}

@keyframes move {
  25% {
    opacity: 1;
  }

  33% {
    opacity: 1;
    transform: translateY(30px);
  }

  67% {
    opacity: 1;
    transform: translateY(40px);
  }

  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes _1_animation__GFw7l {
  0% {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(30deg) scale(0.7);
  }
}

@keyframes _1_animation__GFw7l {
  0% {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(30deg) scale(0.7);
  }
}

@-webkit-keyframes _2_animation__GFw7l {
  0% {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(-30deg) scale(1);
  }
}

@keyframes _2_animation__GFw7l {
  0% {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(-30deg) scale(1);
  }
}

@-webkit-keyframes _3_animation__GFw7l {
  0% {
    transform: rotate(10deg) scale(1);
  }

  to {
    transform: rotate(30deg) scale(0.8);
  }
}

@keyframes _3_animation__GFw7l {
  0% {
    transform: rotate(10deg) scale(1);
  }

  to {
    transform: rotate(30deg) scale(0.8);
  }
}

@keyframes marquee-scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-scroll-reverse {
  100% {
    transform: translateX(100%);
  }

  0% {
    transform: translateX(-100%);
  }
}
