.bonus_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.bonus_left {
  width: 50%;
}
.bonus_right {
  width: 50%;
}
.affiliate_right_title {
  font-size: 12px;
}
.affiliate_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #2a292fc0;
  border-radius: 10px;
  padding: 10px;
}
.aff_link_detail {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
}
.aff_link_detail_link {
  font-size: 15px;
  color: var(--gray-primary-color);
}
.aff_link_title {
  color: #f39c12;
}
.aff_link_detail_link_edit {
  background-color: #2f2e33;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}
.aff_link_detail_link_edit ion-icon {
  color: aliceblue;
  font-size: 15px;
  padding-right: 5px;
}
.aff_link_detail_link_edit:hover {
  cursor: pointer;
}
.aff_link_detail_link_edit.disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #2f2e33; /* Màu nền khi bị vô hiệu hóa */
}

.aff_link_detail_link_edit.disabled:hover {
  background-color: #2f2e33; /* Giữ nguyên màu nền khi hover */
}

.aff_link_detail_link_copy {
  display: flex;
  align-items: center;
  background-color: #2f2e33;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
}

.aff_link_detail_link_copy ion-icon {
  color: aliceblue;
  font-size: 15px;
  padding-right: 5px;
}
.aff_link_detail_link_copy:hover {
  cursor: pointer;
  background-color: var(--green-primary-color);
}

.social_card {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  gap: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
}

/* for all social containers*/
.socialContainer {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(44, 44, 44);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: 0.3s;
}
/* instagram*/
.containerOne:hover {
  background-color: #d62976;
  transition-duration: 0.3s;
}
/* twitter*/
.containerTwo:hover {
  background-color: #00acee;
  transition-duration: 0.3s;
}
/* linkdin*/
.containerThree:hover {
  background-color: #0072b1;
  transition-duration: 0.3s;
}
/* Whatsapp*/
.containerFour:hover {
  background-color: #128c7e;
  transition-duration: 0.3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: 0.3s;
}

.socialSvg {
  width: 17px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.daily_rewards {
  margin-top: 20px;
  align-items: center;
  height: 210px;
  background-color: #2a292fc0;
  border-radius: 10px;
  padding: 10px;
}
.daily_rewards_title {
  display: flex;
  justify-content: space-between;
}
.daily_rewards_title_right {
  font-size: 12px;
  color: #6d6d6d;
}
.streakbox {
  display: flex;
  padding: 10px;
  gap: 10px;
}
.notavaiable {
  color: #616161;
}
.box_items {
  cursor: pointer;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  background-color: #2f2e33;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3f3f3f;
}

.box_items_icon ion-icon {
  font-size: 23px;
}
.box_items_days {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
}
.box_items_reward {
  display: flex;
  font-size: 12px;
  margin-bottom: 10px;
}

.box_items_reward img {
  padding-right: 5px;
  width: 12px;
  opacity: 0.5;
}

.box_items_claim {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 25px;
  background-color: #2a292fc0;
  border-radius: 5px;
  font-size: 12px;
}

.box_items_claim:hover {
  cursor: pointer;
  /* background-color: var(--green-primary-color); */
}

.daily_info {
  display: flex;
  align-items: center;
  background-color: #2f2e33;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  gap: 10px;
}

.time_left {
  color: var(--green-primary-color);
}
.challenges {
  margin-top: 20px;
  align-items: center;
  height: 230px;
  background-color: #2a292fc0;
  border-radius: 10px;
  padding: 10px;
}
.challenges_title {
  display: flex;
  justify-content: space-between;
}
.challenges_title_right {
  font-size: 12px;
  color: #6d6d6d;
}

.challengesbox {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  align-items: center;
  grid-gap: 10px;
  text-align: center;
}

.challengesbox_items {
  cursor: pointer;
  width: 200px;
  height: 80px;
  border-radius: 10px;
  background-color: #2f2e33;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  gap: 5px;
  padding: 5px;
}
.challengesbox_items_title {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.challengesbox_items_title img {
  padding-left: 10px;
  padding-right: 5px;
  width: 12px;
}
.challengesbox_items_reward {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.challengesbox_items_reward img {
  padding-left: 10px;
  padding-right: 5px;
  width: 9px;
}

.challengesbox_items_claim {
  text-align: center;
  width: 90%;
  padding: 5px;
  background-color: #2a292fc0;
  border-radius: 5px;
}

.bonus_box {
  display: flex;
  flex-direction: column;
  height: 200px;
  font-size: 12px;
  background-color: #2a292fc0;
  border-radius: 10px;
  padding: 10px;
}
.bonus_box_title {
  font-size: calc(10px + 1vmin);
  margin-bottom: 10px;
}
.bonus_box_des {
  font-size: 12px;
  margin-bottom: 10px;
}
.inputcode {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100px;
  background-image: url(/public/assets/images/bonus_code_bg.webp);
  background-repeat: no-repeat; /* Không lặp lại hình nền */
  background-size: contain;
}
.search-label {
  height: 30px;
  width: 60%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border: 1px solid transparent;
  border-radius: 12px;
  overflow: hidden;
  background: #3d3d3d;
  cursor: text;
}

.search-label:hover {
  border-color: gray;
}

.search-label:focus-within {
  background: #464646;
  border-color: gray;
}

.search-label input {
  outline: none;
  width: 100%;
  border: none;
  background: none;
  color: rgb(162, 162, 162);
}

.search-label input:focus + .slash-icon,
.search-label input:valid + .slash-icon {
  display: none;
}

.search-label input:valid ~ .search-icon {
  display: block;
}

.search-label input:valid {
  width: calc(100% - 22px);
  transform: translateX(20px);
}

.search-label svg,
.slash-icon {
  position: absolute;
  color: #7e7e7e;
}

.search-icon {
  display: none;
  width: 12px;
  height: auto;
}

.slash-icon {
  right: 7px;
  border: 1px solid #393838;
  background: linear-gradient(-225deg, #343434, #6d6d6d);
  border-radius: 3px;
  text-align: center;
  box-shadow: inset 0 -2px 0 0 #3f3f3f, inset 0 0 1px 1px rgb(94, 93, 93),
    0 1px 2px 1px rgba(28, 28, 29, 0.4);
  cursor: pointer;
  font-size: 12px;
  width: 15px;
}

.slash-icon:active {
  box-shadow: inset 0 1px 0 0 #3f3f3f, inset 0 0 1px 1px rgb(94, 93, 93),
    0 1px 2px 0 rgba(28, 28, 29, 0.4);
  text-shadow: 0 1px 0 #7e7e7e;
  color: transparent;
}

.btn_bonus {
  height: 30px;
  position: relative;

  border-radius: 7px;
  border: 1px solid rgb(61, 106, 255);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  background: transparent;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.btn_bonus:hover {
  background: rgb(61, 106, 255);
  box-shadow: 0 0 30px 5px rgba(0, 142, 236, 0.815);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.btn_bonus:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}
.btn_bonus::before {
  content: "";
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 20px 10px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

.btn_bonus:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}
