.cash_section {
  margin-bottom: 30px;
}

.cash_header_title {
  font-size: 17px;
}

.cash_header_description {
  font-size: 12px;
  color: var(--orange-darkmode);
}

.card_method {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 190px;
  height: 94px;

  margin-bottom: 15px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.card_method_logo {
  display: flex;
  justify-content: center;
}
.card_method_logo img {
  width: 100%;
}
.method_list {
  padding-top: 20px;

  display: grid;
  grid-template-columns: repeat(6, 0fr);
  align-items: center;
  grid-gap: 20px;
}

.paypal {
  background: rgb(87, 161, 249);
  background: linear-gradient(
    90deg,
    rgba(87, 161, 249, 1) 0%,
    rgba(16, 121, 243, 1) 100%
  );
}

.bitcoin {
  background: rgb(248, 168, 71);
  background: linear-gradient(
    90deg,
    rgba(248, 168, 71, 1) 0%,
    rgba(247, 148, 29, 1) 100%
  );
}

.eth {
  background: rgb(91, 106, 189);
  background: linear-gradient(
    90deg,
    rgba(91, 106, 189, 1) 0%,
    rgba(127, 128, 189, 1) 100%
  );
}

.ltc {
  background: rgb(117, 119, 149);
  background: linear-gradient(
    90deg,
    rgba(117, 119, 149, 1) 0%,
    rgba(96, 97, 120, 1) 100%
  );
}

.gift_section {
  margin-bottom: 30px;
}

.gift_header_title {
  font-size: 17px;
}

.gift_header_description {
  font-size: 12px;
  color: var(--border-color);
}

.amazon {
  background: rgb(44, 44, 45);
  background: -moz-linear-gradient(
    90deg,
    rgba(44, 44, 45, 1) 0%,
    rgba(33, 34, 34, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(44, 44, 45, 1) 0%,
    rgba(33, 34, 34, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(44, 44, 45, 1) 0%,
    rgba(33, 34, 34, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2c2c2d", endColorstr="#212222", GradientType=1);
}

.apple {
  background: rgb(255, 91, 83);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 91, 83, 1) 0%,
    rgba(152, 80, 250, 1) 47%,
    rgba(44, 201, 248, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 91, 83, 1) 0%,
    rgba(152, 80, 250, 1) 47%,
    rgba(44, 201, 248, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 91, 83, 1) 0%,
    rgba(152, 80, 250, 1) 47%,
    rgba(44, 201, 248, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff5b53", endColorstr="#2cc9f8", GradientType=1);
}

.google {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(231, 231, 231, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(231, 231, 231, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(231, 231, 231, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#e7e7e7", GradientType=1);
}

.steam {
  background: rgb(70, 72, 135);
  background: -moz-linear-gradient(
    90deg,
    rgba(70, 72, 135, 1) 0%,
    rgba(35, 38, 81, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(70, 72, 135, 1) 0%,
    rgba(35, 38, 81, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(70, 72, 135, 1) 0%,
    rgba(35, 38, 81, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#464887", endColorstr="#232651", GradientType=1);
}

.netflix {
  background: rgb(44, 44, 45);
  background: -moz-linear-gradient(
    90deg,
    rgba(44, 44, 45, 1) 0%,
    rgba(33, 34, 34, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(44, 44, 45, 1) 0%,
    rgba(33, 34, 34, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(44, 44, 45, 1) 0%,
    rgba(33, 34, 34, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2c2c2d", endColorstr="#212222", GradientType=1);
}

.spotify {
  background: rgb(122, 181, 92);
  background: -moz-linear-gradient(
    90deg,
    rgba(122, 181, 92, 1) 0%,
    rgba(85, 222, 149, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(122, 181, 92, 1) 0%,
    rgba(85, 222, 149, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(122, 181, 92, 1) 0%,
    rgba(85, 222, 149, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7ab55c", endColorstr="#55de95", GradientType=1);
}

.ubereats {
  background: rgb(109, 164, 111);
  background: -moz-linear-gradient(
    90deg,
    rgba(109, 164, 111, 1) 0%,
    rgba(24, 119, 22, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(109, 164, 111, 1) 0%,
    rgba(24, 119, 22, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(109, 164, 111, 1) 0%,
    rgba(24, 119, 22, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6da46f", endColorstr="#187716", GradientType=1);
}

.nintendo {
  background: rgb(225, 56, 56);
  background: -moz-linear-gradient(
    90deg,
    rgba(225, 56, 56, 1) 0%,
    rgba(205, 59, 57, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(225, 56, 56, 1) 0%,
    rgba(205, 59, 57, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(225, 56, 56, 1) 0%,
    rgba(205, 59, 57, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e13838", endColorstr="#cd3b39", GradientType=1);
}

.roblox {
  background: rgb(255, 35, 35);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 35, 35, 1) 0%,
    rgba(209, 15, 50, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 35, 35, 1) 0%,
    rgba(209, 15, 50, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 35, 35, 1) 0%,
    rgba(209, 15, 50, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff2323", endColorstr="#d10f32", GradientType=1);
}
