.webbody {
  height: 100vh;
  font-family: "Roboto";
  background: #301b52;
  color: #8371b1;
}

.message {
  margin-top: 20px;
  color: #049821;
  font-size: 15px;
}
.offer_frame {
  height: 100%;
  width: 100%;
}

.fullframe {
  height: calc(100vh - 70px);
  margin-top: 70px;
}

.mainheader {
  position: fixed;
  top: 0;
  display: block;
  background-color: #301b52;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  z-index: 999;
}

.left-navigation {
  font-family: "Roboto";
  position: fixed;
  top: 0;
  left: -300px;
  background-color: #22133a;
  width: 227px;
  z-index: 10;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-navigation .left-nav-header {
  display: block;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left-navigation .left-nav-header .logo {
}

.mainheader .topheader {
  display: flex;
  align-items: center;
  width: 100%;
}

.topheader .left-menu-icon {
  z-index: 999;
  align-items: center;
  justify-content: center;
  margin: 20px;
  display: none;
  width: 40px;
  height: 27px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: poRoboto;
}
.topheader .left-menu-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
  background: rgba(250, 250, 250, 0.8);
  border-top: 1px solid #606075;
  opacity: 1;
  left: 10px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.topheader .left-menu-icon img {
  top: 5px;
  width: 5px;
}
.topheader .left-menu-icon span:nth-child(1) {
  top: 5px;
}
.topheader .left-menu-icon span:nth-child(2) {
  top: 12px;
  width: 12px;
}
.topheader .left-menu-icon span:nth-child(3) {
  top: 19px;
}

.topheader .left-menu-icon.open span:nth-child(1) {
  top: 7px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.topheader .left-menu-icon.open span:nth-child(2) {
  opacity: 0;
  left: -20px;
}
.topheader .left-menu-icon.open span:nth-child(3) {
  top: 7px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.topheader .left-menu-icon {
  display: block;
}

.topheader .logo {
  width: auto;
}
.topheader .logo img {
  padding-left: 50px;
  float: right;
  width: 120px;
}

.topheader .navigation .account {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.account {
  margin-right: 40px;
  margin-left: auto;
}
.account .account-item {
  margin-left: 15px;
}
.account a {
  text-decoration: none;
}

body .webhome-page {
  padding-top: 150px;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 2px;
  margin-bottom: 30px;
}

.home-page .main_heading {
  display: flex;
  width: 100%;
}

.home-page .main_content {
  width: 100%;
}

/* ===================================================================== WEBLEAD*/

.account {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0px;
  margin-left: auto;
  border-radius: 20px;
}
.account .account-item {
  margin-left: 5px;
}

.account .user-box {
  height: 53px;
  padding: 2px 5px;
  background-color: #150a30;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.account .user-box .profile-btn {
  cursor: poRoboto;
  margin-right: 10px;
}
.account .user-box .profile-btn svg {
  width: 12px;
  height: 9px;
}
.account .user-box .profile-link {
  width: 34px;
  height: 34px;
  position: relative;
  top: 1px;
  margin-right: 10px;
}
.account .user-box .avatar {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 4px;
}
.account .user-box .user-info {
  display: block;
  text-align: left;
  float: right;
  width: 86px;
}
.account .user-box .user-info .username {
  font-weight: 600;
  font-size: 12px;
  padding-left: 3px;
  line-height: normal;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.account .user-box .user-info .balance-container {
  position: relative;
}
.account .user-box .user-info .balance-container .balance-row {
  width: 100%;
  height: 21px;
  background-color: #212135;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
  padding: 0 4px;
  cursor: poRoboto;
}
.account .user-box .user-info .balance-container .amount {
  font-weight: 600;
  font-size: 11.5px;
  line-height: 1.5em;
  color: #f5c61d;
  text-align: center;
  max-height: 15px;
}
.account .user-box .user-info .balance-container .amount + svg {
  margin-left: auto;
  cursor: poRoboto;
  padding: 3px 0;
  height: 15px;
  width: 12px;
  position: relative;
  top: 1px;
}

.account .user-box .user-info .balance-container svg.show-currency,
.account .user-box .user-info .balance-container svg.show-coins {
  width: 11px;
  height: 11px;
}
.account .user-box .user-info .balance-container.active .currency-picker {
  display: flex;
}
.account .user-box .user-info .balance-container.on-currency .show-currency {
  display: block;
}
.account .user-box .user-info .balance-container.on-currency .show-coins {
  display: none;
}
.account .user-box .user-info .balance-container .show-currency {
  display: none;
}
.account .user-box .user-info .balance-container .currency-picker {
  position: absolute;
  top: calc(100% + 6px);
  right: -10px;
  width: 144px;
  height: 36px;
  background: #48486c;
  border-radius: 5px;
  align-items: center;
  gap: 8px;
  padding: 10px;
  display: none;
}
.account .user-box .user-info .balance-container .currency-picker::before {
  content: "";
  position: absolute;
  right: 12px;
  top: -11px;
  border: 7.21px solid;
  border-color: transparent transparent #48486c transparent;
}
.account .user-box .user-info .balance-container .currency-picker .lever {
  width: 30px;
  height: 16px;
  background: #212135;
  border-radius: 4px;
  margin: 0;
}

.account
  .user-box
  .user-info
  .balance-container
  .currency-picker
  .lever::before,
.account
  .user-box
  .user-info
  .balance-container
  .currency-picker
  .lever::after {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  top: 2px;
  left: 2px;
}
.account
  .user-box
  .user-info
  .balance-container
  .currency-picker
  .lever::after {
  background: #66668b;
}
.account
  .user-box
  .user-info
  .balance-container
  .currency-picker
  input[type="checkbox"]:checked
  + .lever::before,
.account
  .user-box
  .user-info
  .balance-container
  .currency-picker
  input[type="checkbox"]:checked
  + .lever::after {
  left: 16px;
}
.account
  .user-box
  .user-info
  .balance-container
  .currency-picker
  input[type="checkbox"]:checked
  + .lever::after {
  background: #f5c61d;
}
.account .user-box .user-info .balance-container .currency-picker .show-coins,
.account
  .user-box
  .user-info
  .balance-container
  .currency-picker
  .show-currency {
  font-weight: 600;
  font-size: 9px;
  line-height: 13px;
  color: #fff;
  white-space: nowrap;
}

.offerwall_section {
  width: 100%;
  background-color: #150a30;
  border-radius: 10px;
  height: 200px;
}

.checker-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure the container takes up the full height of its parent */
}

.checker {
  margin-top: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.checker img {
  width: 60px;
  height: 60px;
}

.earnpage {
  background-color: #150a30;
  width: 100%;
  border-radius: 10px;
}

.earnpage .earn-page-section {
  background-color: #150a30;
  padding-top: 20px;
  margin: 20px;
}

.earnpage .earnheader {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.earnheader .earnHeaderTitleContainer {
  display: flex;
  flex-direction: row;
}
.earnHeaderTitle {
  color: #8371b1;
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.earnHeader .earnHeaderTitle {
  color: #8371b1;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.earnpage .earnheader .earnHeaderIcon {
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.earnheader .earnHeaderPopularity {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;

  color: #a9a9ca;
}

.offerwall-section {
  width: 100%;
}

.offerwall-section .network__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  text-align: center;
  padding: 8px;
  margin: 8px;
  transition: all 0.3s;
  padding-bottom: 30px;
}

.network__list .item {
  display: flex;
  position: relative;
  width: 150px;
  height: 200px;
  margin: 10px;
  text-align: center;
  border-radius: 15px;
  transition: all 0.3s;
  cursor: poRoboto;
  box-shadow: rgba(37, 8, 116, 0.308) 0px 10px 10px -10px;
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.adgate {
  background-color: #301b52;
}

.network__list .item .network_logo {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.network__list .item .network_logo .earn-box-play-button {
  opacity: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgba(1, 214, 118, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 50%;
  transition: all 0.3s;
}

.network__list .item .network_logo .earn-box-view-button {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 50px;
  border-radius: 50%;
  transition: all 0.3s;
  display: none;
}
.network_logo .earn-box-view-button span {
  display: none;
  color: #a9a9ca;
  font-size: 10px;
  font-weight: 400;
}

.network__list .item .network_logo .earn-box-play-button img {
  display: none;
  height: 20px;
  width: 20px;
}

.network__list .item .network_logo img {
  text-align: center;
  width: 70%;
  max-height: 60px;
  object-fit: contain;
  border-radius: 0px;
}

.network__list .item p {
  font-size: 14px;
  font-weight: 400;
  color: #65678d;
  transition: color 0.3s;
}

.network__list .item .wallName {
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 15px;
  color: #a9a9ca;
  text-align: center;
  text-transform: uppercase;
  display: none;
}

.network__list .item:hover .earn-box-rating {
}

.network__list .item:hover .network_logo .earn-box-network-button {
  width: 100%;
}

.network__list .item:hover .network_logo .earn-box-play-button {
  opacity: 0;
}

.network__list .item:hover .network_logo .earn-box-view-button {
  opacity: 0;
}

/* ===================================================================== MODAL*/
.webmodal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: rgba(229, 231, 235, 0.01);
  backdrop-filter: blur(1.5px);
}

.webmodal .earnWallModal {
  width: 50%;
  height: 90%;
  position: fixed;
  justify-content: center;
  z-index: 9999;
  background-color: #222337;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.webmodal .earnWallModal .modal-content {
  height: 100%;
  padding: 20px;
}

.webmodal .earnWallModal .modal-content .modalTitle {
  margin: 20px;
}
.webmodal .earnWallModal .modal-content .modal-close {
  z-index: 1;
}
.closeModalBtn {
  float: right;
  position: relative;
  bottom: 4px;
  left: 14px;
  color: #049821;
  cursor: poRoboto;
}

.modal-content .earnModalIframeContain {
  height: 90%;
  border-radius: 10px;
}
.clickerframe {
  height: 90%;
  border-radius: 10px;
}

/* ===================================================================== WEBLOGIN*/

.form-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-content a {
  text-decoration: none;
}

.signup-container {
  margin-top: 80px;
  position: relative;
  max-width: 600px;
  width: 100%;
  background: #150a30;
  box-shadow: 0 4px 5px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 50px 50px 50px 50px;
  text-align: center;
  font-size: 0;
}

.signup-form {
  padding-top: 20px;
}

.signup-container .title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.03em;
  margin-bottom: 5px;
  line-height: 129.5%;
}

.signup-container .description {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.03em;
  margin-bottom: 10px;
  line-height: 129.5%;
}

.signup-container .signup-content .signup-with {
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
  gap: 12px;
}

.signup-container .signup-content .signup-with .signup-button {
  width: calc(50% - 6px);
  height: 37px;
  background: #25253b;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
}
.signup-container .signup-content .signup-with .signup-button span {
  font-weight: 500;
  font-size: 10px;
}
.signup-container .signup-content .divide-line {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.03em;
  margin-bottom: 11px;
  line-height: 129.5%;
}
.signup-container .signup-content .divide-line::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 6.5px;
}

.signup-container .signup-content .signup-input {
  text-align: center;
}

.signup-container .signup-content .signup-input label {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 9px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #fff;
  display: inline-block;
}
.signup-container .signup-content .signup-input input {
  color: #8371b1;
  width: 100%;
  height: 37px;
  background-color: #e5e1e9;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  margin-bottom: 19px;
  padding: 10px 12px;
  box-sizing: border-box;
  letter-spacing: -0.03em;
}

.signup-container .signup-content .signup-input input:focus {
  outline: none;
}

.signup-container .signup-content .signup-privacy {
  display: block;
  margin-top: 30px;
  text-align: center;
}
.signup-container .signup-content .register-btn {
  width: 130px;
  height: 37px;
  background: #f7d246;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #1d1d2e;
}
.signup-container .signup-content .signup-privacy span {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  padding-left: 24px;
}

.checker-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure the container takes up the full height of its parent */
}

.checker {
  margin-top: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.checker img {
  width: 60px;
  height: 60px;
}

.fullframe {
  height: 100vh;
  margin-top: 0px;
}

.clickerframe {
  height: 90%;
  border-radius: 10px;
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 900px) {
}
@media only screen and (max-width: 600px) {
  .network__list .item .network_logo .earn-box-play-button {
    display: none;
  }
  body .webhome-page {
    padding-top: 80px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 2px;
    margin-bottom: 30px;
  }
  .earnpage .earn-page-section {
    width: 100%;
    background-color: #150a30;
    padding-top: 0px;
    margin: 0px;
    border-radius: 5px;
  }
  .offerwall-section .network__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
    transition: all 0.3s;
    padding-bottom: 10px;
  }
  .network__list .item {
    display: block;
    position: relative;
    width: 40%;
    height: 100px;
    text-align: center;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: poRoboto;
    box-shadow: rgb(37 8 116 / 31%) 0px 10px 10px -10px;
    background-size: cover;
  }
  .network__list .item .network_logo {
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .network__list .item .wallName {
    display: none;
  }
  .webmodal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100%;
    height: 100%;
    background-color: rgba(229, 231, 235, 0.01);
    backdrop-filter: blur(1.5px);
  }
  .webmodal .earnWallModal {
    width: 100%;
    height: 100%;
  }
  .webmodal .earnWallModal .modal-content {
    height: 100%;
    padding: 10px;
  }
  .webmodal .earnWallModal .modal-content .modalTitle {
    margin: 5px;
  }
  .webmodal .earnWallModal .modal-content .modal-close {
    z-index: 1;
    padding-right: 10px;
  }
  .modal-content .earnModalIframeContain {
    height: 94%;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 320px) {
}
