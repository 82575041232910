ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
nav {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  background-color: var(--footer-color);
  border-radius: 20px 20px 0px 0px;
  justify-content: center;
  height: 60px;
  overflow-x: auto;
}

nav ul {
  display: flex;
  justify-content: center;
  gap: 10px; /* Điều chỉnh khoảng cách giữa các li */
}

nav ul li {
  position: relative;
  list-style: none;
  width: 80px;
  z-index: 1;
}

ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  position: relative;
  width: 100%;
}

ion-icon {
  line-height: 1; /* Giảm line-height để làm gần hơn giữa icon và title */
  font-size: 23px;
  transition: 0.4s;
  color: rgb(94, 94, 94);
  text-align: center;
}

ul li.active a .fa {
  transform: translateY(-40px);
  color: rgb(94, 93, 93);
}

ul li.active a .title {
  opacity: 1;
  transform: translateY(13px);
}

.navtitle {
  position: absolute;
  top: 18px;
  font-weight: 500;
  font-size: 12px;
  color: rgb(75, 74, 74);
  opacity: 1;
  transition: 0.4s;
  transform: translateY(
    5px
  ); /* Điều chỉnh giá trị translateY để làm cho khoảng cách hợp lý */
}

ul li.active ion-icon,
ul li.active .navtitle {
  color: var(
    --green-primary-color
  ); /* Màu xanh lá cây cho ion-icon và title trong item active */
}
