.crypto-overlay {
  font-family: "Luxora Grotesk Book";
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}

.crypto-modal {
  color: #e1e1e1;
  background-color: var(--background-color);
  margin: 5% auto;
  border-radius: 10px;
  width: 22%;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.crypto-header {
  height: 40px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
}

.crypto .close {
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: var(--background-color);
}

.crypto-content {
  padding: 20px;
  flex: 1;
  padding-top: 20px;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.giftcard_withdraw {
  padding: 5px;
}

.balance {
  padding: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.balance_coins {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.balance_coins img {
  padding-right: 5px;
  width: 20px;
}

.select_local {
  position: relative;
}

#countryDropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  width: 100%;
  background-color: var(--background-dark-color);
  color: white;
  padding: 10px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

#countryDropdown:hover,
#countryDropdown:focus {
  background-color: #3e8e41;
}

.dropdown-content {
  color: var(--gray-primary-color);
  display: none;
  position: absolute;
  background-color: var(--background-dark-color);
  width: 100%;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: var(--gray-primary-color);
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dropdown-content img {
  width: 20px;
}

.dropdown-content a:hover {
  background-color: var(--border-color);
}

.show-dropdown {
  display: block !important;
  /* Use !important to override other styles */
}

.select_text {
  font-size: 12px;
}

input[type="text"]::placeholder {
  color: var(--gray-primary-color);
  font-size: 12px;
}

input[type="number"]::placeholder {
  color: var(--gray-primary-color);
  font-size: 12px;
}

/* Đối với Firefox */
input[type="text"]::-moz-placeholder {
  color: var(--gray-primary-color);
  font-size: 12px;
}

/* Đối với Internet Explorer và Edge */
input[type="text"]:-ms-input-placeholder {
  color: var(--gray-primary-color);
  font-size: 12px;
}

.walletCrypto,
.amountCrypto {
  width: 100%;
  box-sizing: border-box;
  /* Chiều rộng tối đa */
  padding: 10px;
  /* Padding cho input */
  margin: 10px 0;
  /* Margin trên và dưới */
  border: 0px 1px solid #6b6b6b;
  /* Viền cho input */
  border-radius: 5px;
  /* Bo góc cho input */
  font-size: 16px;
  /* Kích thước font */
  background-color: #6b6b6b;
  /* Màu nền cho input */
  outline: none;
  color: #e1e1e1;
  /* Loại bỏ viền màu xanh khi focus */
}

.walletCrypto:focus,
.amountCrypto:focus {
  color: var(--gray-primary-color);
  border-color: #4caf50;
  /* Màu viền khi input được focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.2);
  /* Hiệu ứng bóng khi input được focus */
}

.payout_list {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
}

.card_payout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 54px;
  padding: 10px;
  border-radius: 5px;
}

.card_payout.active {
  background-color: #f1f1f1;
  /* Màu nền khi card được chọn */
  border: 2px solid #4caf50;
  /* Viền màu xanh khi card được chọn */
}

.payout_numb {
  position: absolute;
  top: 5px;
  right: 5px;
}

.get_rate {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.fee {
  margin-top: 20px;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--background-dark-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fee_coins {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fee_coins img {
  padding-right: 5px;
  width: 12px;
}

.payout_prize {
  margin-top: 20px;
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  background-color: var(--background-dark-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payout_prize_numb {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payout_prize_numb img {
  padding-right: 5px;
  width: 15px;
}

.btn_withdraw {
  cursor: pointer;
  margin-top: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191a19;
  border-radius: 5px;
}

.btn_withdraw:hover {
  background-color: #38c438;
  transition: 0.5s ease-in-out;
}
