/* Toast CSS */
:root {
  --success: #49e489;
  --error: #f1655b;
  --warning: #f5bc52;
  --info: #65b5f7;
  --background-color: #ffffff;
  --dark: #000000;
  --light: #ffffff;
}

.notifications {
  z-index: 99999;
  position: fixed;
  bottom: 0px;
  right: 70px;
}

.notifications :where(.toast, .column) {
  display: flex;
  align-items: center;
}

.notifications .toast {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  width: 250px;
  position: relative;
  overflow: hidden;
  list-style: none;
  border-radius: 4px;
  padding: 16px 17px;
  margin-bottom: 10px;
  background: var(--light);
  justify-content: space-between;
  animation: show_toast 0.3s ease forwards;
  font-size: 10px;
  font-family: "Luxora Grotesk Book";
}

@keyframes show_toast {
  0% {
    transform: translateX(100%);
  }

  40% {
    transform: translateX(-5%);
  }

  80% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-10%);
  }
}

.notifications .toast.hide {
  animation: hide_toast 0.3s ease forwards;
}

@keyframes hide_toast {
  0% {
    transform: translateX(-10%);
  }

  40% {
    transform: translateX(0%);
  }

  80% {
    transform: translateX(-5%);
  }

  100% {
    transform: translateX(calc(100% + 20px));
  }
}

@keyframes progress {
  100% {
    width: 0%;
  }
}

.toast::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 100%;
  bottom: 0;
  left: 0;
  animation: progress 3s linear forwards;
}

.toast.success::before,
.btn#success {
  background: var(--success);
}

.toast.error::before,
.btn#error {
  background: var(--error);
}

.toast.warning::before,
.btn#warning {
  background: var(--warning);
}

.toast.info::before,
.btn#info {
  background: var(--info);
}

/* Áp dụng màu sắc dựa trên type */
.toast.success {
  color: var(--success);
}

.toast.error {
  color: var(--error);
}

.toast.warning {
  color: var(--warning);
}

.toast.info {
  color: var(--info);
}

/* CSS cho biểu tượng */
.toast ion-icon {
  font-size: 30px;
}

.column {
  font-size: 20px;
}

.column .mess {
  padding-left: 10px;
  font-size: 12px;
}

.toast.success .column ion-icon {
  color: var(--success);
}

.toast.error .column ion-icon {
  color: var(--error);
}

.toast.warning .column ion-icon {
  color: var(--warning);
}

.toast.info .column ion-icon {
  color: var(--info);
}

.toast .column span {
  color: var(--background-color);
  font-size: 1.07rem;
  margin-left: 12px;
}

.toast i:last-child {
  color: #aeb0d7;
  cursor: pointer;
}

.toast i:last-child:hover {
  color: var(--dark);
}

@media screen and (max-width: 530px) {
  .notifications {
    width: 80%;
  }

  .notifications .toast {
    width: 100%;
    font-size: 1rem;
    margin-left: 20px;
  }
}

.toast ion-icon {
  font-size: 20px;
  color: var(--background-color);
}
